@use 'variables';

.accent {
  color: var(--clr-accent-2);
}

.flex {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-evenly;

  &.flex--vertical {
    flex-flow: column nowrap;

    > * {
      flex: 1 1 100%;
      min-height: 0; // Break overflowing words if flow: column
    }
  }
}


.full-height {
  height: 100vh;
}
