// BREAKPOINTS
$bp-small-mobile-width: 320px;
$bp-mobile-width: 411px;
$bp-tablet-width: 768px;
$bp-laptop-width: 1024px;
$bp-wide-width: 1900px;
$bp-4k-width: 2560px;

$bp-site-max-width: 1300px;

// SPACING
$padding-section: 1rem;

// CSS VARS
:root {
  color-scheme: light dark;

  // Logo
  --margin-logo-top: 10vh;
}

body.theme-light {
  // PALETTE (LIGHT MODE) - https://coolors.co/171a26-fffcf5-3423a6-ec4680-e2ef70
  $clr-raisin-black: #1A202C;
  $clr-floral-white: #FDF8EC;
  $clr-blue-pantone: #3423A6;
  $clr-french-rose: #EC4680;
  $clr-key-lime: #E2EF70;

  --clr-accent-1: #{$clr-blue-pantone};
  --clr-accent-2: #{$clr-french-rose};
  --clr-background: #{$clr-floral-white};
  --clr-bubble-bg: #{$clr-blue-pantone};
  --clr-bubble-menu-bg: #{$clr-blue-pantone};
  --clr-bubble-text: #{$clr-floral-white};
  --clr-bubble-text-hover: #{$clr-key-lime};
  --clr-footer-bg: #{$clr-blue-pantone};
  --clr-footer-text: #{$clr-floral-white};
  --clr-headings: #{$clr-blue-pantone};
  --clr-highlight: #{$clr-key-lime};
  --clr-highlight-border: #{$clr-french-rose};
  --clr-link-decoration: lightgray;
  --clr-link-hover-decoration: #{$clr-french-rose};
  --clr-link-hover-text: #{$clr-blue-pantone};
  --clr-link-text: #{$clr-french-rose};
  --clr-salary-background: #{$clr-blue-pantone};
  --clr-salary-foreground: #{$clr-floral-white};
  --clr-salary-text: #{$clr-blue-pantone};
  --clr-text: #{$clr-raisin-black};
  --clr-text-inverted: #{$clr-floral-white};
}

body.theme-dark {
  // PALETTE (DARK MODE) - https://coolors.co/palette/fffcf5-d0e7e7-46ecb2-e2ef70-3424a6-1a202c
  $clr-blue-pantone: #3423A6;
  $clr-raisin-black: #1A202C;
  $clr-turquoise: #46ECB2;
  $clr-floral-white: #FDF8EC;

  --clr-accent-1: #{$clr-floral-white};
  --clr-accent-2: #{$clr-turquoise};
  --clr-background: #{$clr-raisin-black};
  --clr-bubble-bg: #{$clr-blue-pantone};
  --clr-bubble-menu-bg: #{$clr-blue-pantone};
  --clr-bubble-text: #{$clr-floral-white};
  --clr-bubble-text-hover: #{$clr-floral-white};
  --clr-footer-bg: #0F131A;
  --clr-footer-text: #{$clr-floral-white};
  --clr-headings: #{$clr-floral-white};
  --clr-highlight: #{$clr-turquoise};
  --clr-highlight-border: #{$clr-floral-white};
  --clr-link-decoration: #{$clr-floral-white};
  --clr-link-hover-decoration: #{$clr-turquoise};
  --clr-link-hover-text: #{$clr-floral-white};
  --clr-link-text: #{$clr-turquoise};
  --clr-salary-background: #{$clr-blue-pantone};
  --clr-salary-foreground: #{$clr-floral-white};
  --clr-salary-text: #{$clr-blue-pantone};
  --clr-text: #{$clr-floral-white};
  --clr-text-inverted: #{$clr-raisin-black};
}
