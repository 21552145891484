@use 'variables';
@use 'mixins';
@use 'home-mixins';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;

  @media screen and (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

img {
  max-width: 100%;
}

body {
  background-color: var(--clr-background);
  color: var(--clr-text);
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  line-height: 1.5;
  margin: 0;
  min-height: 100vh;

  @media(min-width: variables.$bp-tablet-width) {
    font-size: 20px;
    line-height: 1.75;
  }

  :focus-visible {
    @include mixins.outline();
  }
}

a {
  border-radius: .35em;
  color: var(--clr-link-text);
  font-weight: bold;
  padding: 3px;
  text-decoration-color: var(--clr-link-decoration);
  text-decoration-thickness: auto;
  text-underline-offset: .175rem;

  &:hover,
  &:focus {
    color: var(--clr-link-hover-text);
    text-decoration-color: var(--clr-link-hover-decoration);
  }

  &:visited {
    font-weight: 600;
  }
}

p {
  letter-spacing: .25px;
  margin: 0 0 3vh 0;
  padding: 0;

  &:last-child {
    margin: 0;
  }
}
