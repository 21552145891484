@use 'mixins';
@use 'variables';

h1,
h2,
h3,
h4 {
  font-family: "Josefin Sans", sans-serif;
  margin: 0;
  padding: 0;
}

h1 {
  @include mixins.heading();
}

h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 3vh 0;
  padding: 0;

  @media(min-width: variables.$bp-tablet-width) {
    h2 {
      font-size: 26px;
      line-height: 30px;
    }
  }
}
