@use 'variables';

@mixin heading($font-size: 24px, $font-size-tablet: 30px, $font-size-laptop: 40px) {
  color: var(--clr-headings);
  font-family: "Josefin Sans", sans-serif;
  font-size: $font-size;
  font-weight: 900;
  line-height: 1.2;

  @media(min-width: variables.$bp-tablet-width) {
    font-size: $font-size-tablet;
  }

  @media(min-width: variables.$bp-laptop-width) {
    font-size: $font-size-laptop;
  }
}

/**
 * Style of outline (for focused elements)
 */
@mixin outline($outline-offset: 3px) {
  border-radius: 5px;
  outline: var(--clr-highlight-border) dashed 2px;
  outline-offset: $outline-offset;
}
